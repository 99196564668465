import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HomeService } from './service/home.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { SharedService } from "../shared/service/shared.service";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ValidationService } from '../../_services/index';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css'],
	providers: [HomeService, SharedService]
})
export class HomeComponent implements OnInit {

	SITE_IMAGE_URL: string = "";
	sliderData: any = {};
	blockData: any = {};
	slug: any = {};
	postCodeAvailbilityFormError	:	any		=	{};
	postCodeAvailbilityForm: FormGroup;
	postCodeData: any = {};
	consignmentNumberCheck		:	any		=	{};
	
	constructor(private HomeService: HomeService, private route: ActivatedRoute, public router: Router, private formBuilder: FormBuilder) {
		this.SITE_IMAGE_URL			= environment.SITE_IMAGE_URL;
		this.consignmentNumberCheck = environment.CONSIGNMENT_NUMBER_CHECK;
	}

	ngOnInit() {
		
		let that		=	this;

		/**This function are used for get the slider data **/
		this.getSlider();


		/**This function are used for get blocks data on site **/
		this.getBlocks();

		/**This function are used for check post code avilability or not **/
		this.createPostCodeAvailablityForm();
		
		/**This function are used for search the consignment number on enter **/
		$("#track_parcal").keyup(function(event) {
			if (event.keyCode === 13) {
				let consignmentNumberValue		=	$(this).val();
				that.trackParcel(consignmentNumberValue);
			}
		});
		
		/**This function are used for search the post code availability on enter**/
		$("#check_post_code").keyup(function(event) {
			if (event.keyCode === 13) {
				that.checkServiceAvailablity();
			}
		});
	}

	/**This function are used for check post code avilability or not **/
	public createPostCodeAvailablityForm() {
		this.postCodeAvailbilityForm = this.formBuilder.group({
			'post_code': ['', Validators.required]
		});
	}




	/** This function are used for get the slider data **/
	public getSlider() {
		this.HomeService.getSliderData().subscribe((res: any) => {
			this.sliderData = res;
		});
	}

	/** This function are used for submit the postCodeAvailbilityForm**/
	public checkServiceAvailablity() {
		if (this.postCodeAvailbilityForm.valid) {
			this.HomeService.checkPostCodeAvailabilityData(this.postCodeAvailbilityForm.value).subscribe((res: any) => {
				this.postCodeData = res.data;
				if (this.postCodeData.success == true) {
					showSuccessCenter(this.postCodeData.message);
				} else {
					showErrorCenter(this.postCodeData.message);
				}
			});
		} else {
			ValidationService.validateAllFormFields(this.postCodeAvailbilityForm);
		}
	}

	/**This function are used for get the blocks data **/
	public getBlocks() {
		this.slug = 'offers';
		this.HomeService.getBlockData(this.slug).subscribe((res: any) => {
			this.blockData = res.data.data;
		});
	}
	
	
	/**This function are used for navigate the rate calculator**/
	public navigateRateCalulator(){
		this.router.navigate(['/quick-rate-card']);
	}
	
	
    /**This function are used for track parcal **/ 
	public  trackParcel(consignment_number){
		blockUI();
		let consignmentNumber		=	$.trim(consignment_number);
		if(consignmentNumber != ''){
			this.router.navigate(['/trackparcel/'+consignmentNumber]);
			unblockUI();
		}else{
			showErrorCenter(this.consignmentNumberCheck);
			unblockUI();
		}
	}
	
}
