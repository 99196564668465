import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import {
   HeaderComponent,
   FooterComponent, 
   ForgotComponent,
   VerificationComponent,
   ResetpasswordComponent,
   SignupVerificationComponent,
   PaginationComponent,
   TrackparcalresultComponent,
   PaymentverificationComponent} from './layout/index';
import { ControlMessagesComponent } from '../../control-messages.component';
import { KeysPipe } from '../../_pipes/index';
import { ReverseKeysPipe,DisplayPricePipe } from '../../_pipes/index';
import { ShowAuthedDirective } from '../../directive/show-authed.directive';
import { LogoutDirective } from '../../directive/logout.directive';
import { LeftMenuComponent } from './layout/elements/leftmenu.component';
import { BreadcrumbComponent } from './layout/elements/breadcrumb.component';

/***********layout routing ************/
 const sharedRouting = RouterModule.forChild([
	{
		path: 'payment/:status/:consignment_number',
		component: PaymentverificationComponent
   	}
	
 ]);


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
	sharedRouting,
    RouterModule
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    ForgotComponent,
    VerificationComponent,
    ResetpasswordComponent,
    ControlMessagesComponent,
    KeysPipe,
    ReverseKeysPipe,
    ShowAuthedDirective,
    LogoutDirective,
    LeftMenuComponent,
    BreadcrumbComponent,
    SignupVerificationComponent,
    PaginationComponent,
	TrackparcalresultComponent,
	PaymentverificationComponent,
	DisplayPricePipe,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    KeysPipe,
    ReverseKeysPipe,
    RouterModule,
    HeaderComponent,
    FooterComponent,
    ForgotComponent,
    VerificationComponent,
    ControlMessagesComponent,
    ResetpasswordComponent,
    LogoutDirective,
    LeftMenuComponent,
    BreadcrumbComponent,
    SignupVerificationComponent,
    PaginationComponent,
	PaymentverificationComponent,
	TrackparcalresultComponent,
	ShowAuthedDirective,
	DisplayPricePipe
  ],
  providers: [],
})
export class SharedModule { }
