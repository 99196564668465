import { BrowserModule } from '@angular/platform-browser';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from './modules/shared/shared.module';
import { HomeModule } from './modules/home/home.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider, LinkedInLoginProvider} from "angularx-social-login";
import { Select2Module } from 'ng2-select2';
import { TokenInterceptorService } from './token-interceptor.service';
import { SharedService } from "./modules/shared/service/shared.service";
import { Error404Component } from './modules/error404/error404.component';
import { UserService } from './_services/user.service';

/** Importing Guards */
import { AuthGuard } from './guards/index';



/***********Home page routing ************/
const rootRouting: ModuleWithProviders = RouterModule.forRoot([
  { path: 'pages', loadChildren: './modules/cms/cms.module#CmsModule' },
  { path: 'contact-us', loadChildren: './modules/contact/contact.module#ContactModule' },
  { path: 'login', loadChildren: './modules/login/login.module#LoginModule' },
  { path: 'signup', loadChildren: './modules/signup/signup.module#SignupModule' },
  { path: 'user', loadChildren: './modules/user/user.module#UserModule'},
  { path: 'orders', loadChildren: './modules/orders/orders.module#OrdersModule' },
  { path: 'trackparcel', loadChildren: './modules/trackparcel/trackparcel.module#TrackParcelModule' },
  { path: 'rate-card', loadChildren: './modules/ratecard/ratecard.module#RateCardModule' },
  { path: 'company-request', loadChildren: './modules/companyrequest/companyrequest.module#CompanyRequestModule' },
  { path: 'quick-rate-card', loadChildren: './modules/quickratecard/quickratecard.module#QuickRateCardModule' },
  { path: 'weight-calculator', loadChildren: './modules/weightcalculator/weightcalculator.module#WeightCalculatorModule'},
  { path: 'service-pincodes', loadChildren: './modules/servicepincode/servicepincode.module#ServicePincodeModule' },
  { path: '**', component: Error404Component },
]);


/**This configration set for facebook and google social login**/
let config = new AuthServiceConfig([ 
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("528961187921-ld24b25466u4t2lacn9r35asg000lfis.apps.googleusercontent.com")
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("561602290896109")
  }
]);

export function provideConfig() {
  return config;
}




@NgModule({
  declarations: [
    AppComponent,
    Error404Component,
  ],
  imports: [
    BrowserModule,
    SharedModule,
    HomeModule,
    rootRouting,
    FormsModule,
    SocialLoginModule,
    Select2Module,
    ReactiveFormsModule,
    HttpClientModule, CommonModule
  ],
  providers: [
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    SharedService, UserService,
    AuthGuard
  ],
  exports: [CommonModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
