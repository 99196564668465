import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { SharedService } from "../../../shared/service/shared.service";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ValidationService } from '../../../../_services/index';
import { OrderService } from '../../../orders/service/order.service';

@Component({
  selector: 'app-trackparcalresult',
  templateUrl: './trackparcalresult.component.html',
  providers: [OrderService,SharedService]
})
export class TrackparcalresultComponent implements OnInit {
	
  /**Variable Decleartion **/
  trackingHistory		:	any		=	[];  
  bookingDetail			:	any		=	[];
  
  constructor(public router: Router, private formBuilder: FormBuilder,public route: ActivatedRoute,public orderService: OrderService) { }
  
  ngOnInit() {
		this.getParcelTrackingHistory($("#consignment_number_val").val());
  }
  
  
  /*Function to get parcel tracking history */
   getParcelTrackingHistory(consignment_number){
    let formData        =   new FormData();
    formData.append('consignment_number',consignment_number);		
    this.orderService.trackParcel(formData).subscribe((response: any) => {
      if(response.data.success == true){
        this.trackingHistory		=	(typeof(response.data.data.track_parcel_history) != 'undefined') ? response.data.data.track_parcel_history : [];
		this.bookingDetail			=	(typeof(response.data.data.booking_detail) != 'undefined') ? response.data.data.booking_detail : [];
		
      }
    });
   }

} // End of Class
