// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL								:"https://api.pcfcourierlogistics.com/api/index",
  SITE_URL								:"https://pcfcourierlogistics.com",
  SITE_ADMIN_URL						:"https://api.pcfcourierlogistics.com",
  SITE_TITLE 							: 'Blaato',
  PAGE_TITLES							:{ 'HOME':'Home'},
  SITE_IMAGE_URL						:"/assets/images/",
  VALID_EMAIL_ADDRESS_MSG 				: "Please enter email/phone number.",
  VALID_PASSWORD_MSG   					: "Please enter password.",
  PASSWORD_NOT_MATCH_MSG 				: "Passwords do not match.",
  VALID_PASSWORD_VALIDATION_MSG			: "Password must have minimum 8 character and should contain atleast 1 alphabet and 1 number.",
  VALID_ADHAR_CARD_NUMBER_MSG			: "The Adhar Card Number structure is as follows: AAAP L1234 C321",
  REQUIRED_MESSAGE						: "This field is required.",
  MAX_FILE_UPLOAD_LIMIT					: 2,
  VALID_SIZE_IMAGE_ERROR_MSG			: 'Please upload valid size image. Maximum uploaded file size',
  CONTACT_SUCCESS 						: "Your message has been successfully submitted. Site admin will contact you soon.",
  DIAL_CODE								:  "+91",
  ERROR_404								: 'ERROR 404',
  ERROR_DESCRIPTION						: 'Oopss... this is embarassing, either you tried to access a non existing page, or our server has gone crazy.',
  ERROR_CLICK_HERE						: '<a href="#">Click Here</a> to go back home',
  RESEND_MAIL_TIME_IN_SECOND			: 10,
  UPDATE_PROFILE_TOOLTIP_TEXT			: 'Click here to update profile picture. Valid extensions are jpeg, jpg and png.',
  UPLOAD_IMAGE_ERROR_MSG				: 'Please upload image.',
  VALIDATE_EMAIL_ADDRESS_MSG			: 'Please enter valid email address.',
  LOGOUT_MSG							: 'you have successfully logged out.',
  SELECTED_RATE_CARD_ERROR				: 'The selected rate card field is required.', 
  RATE_CARD_NOT_AVAILABLE				: 'Rate Card is not available for this location',
  CONSIGNMENT_NUMBER_CHECK				: 'Please enter consignment number.',
  RATING_ERROR							: 'Please select rating.',
  LESS_RATING_ERROR						: 'Please select reason for less rating.',
  ARE_YOU_SURE							: 'Are you sure?',
  CANCEL_BOOKING_TEXT					: "You want to cancel this booking.You won't be able to revert this!",	
  VALID_OTP_MESSAGE						: "Please enter valid OTP",	
  DATE_FORMAT							: "yyyy/mm/dd",
  HOME_BREADCRUM_TEXT					: "Home",
  GROUP_BOOKINGS_BREADCRUM_TEXT			: "Group Bookings",
  ADD_GROUP_BREADCRUM_TEXT				: "Add Group",
  ADD_GROUP_BOOKING_BREADCRUM_TEXT		: "Add Group Booking",
  ADD_BOOKING_BREADCRUM_TEXT			: "Add Booking",
  GROUP_BOOKING_BREADCRUM_TEXT			: "Group Booking",
  BOOKING_COMPLAINTS_BREADCRUM_TEXT		: "Booking Complaints",
  MY_ORDERS_BREADCRUM_TEXT				: "My Orders",
  ORDER_DETAILS_BREADCRUM_TEXT			: "Order Details",
  TRACK_PARCAL_BREADCRUM_TEXT			: "Track Parcel",
  RATE_CARD_BREADCRUM_TEXT				: "Rate Card",
  ADDRESS_BOOK_BREADCRUM_TEXT			: "Address Book",
  ADD_ADDRESS_BOOK_BREADCRUM_TEXT		: "Add Address Book",
  CARGO_CREDIT_TRANSACTIONS_BREADCRUM_TEXT	:	"Cargo Credit Transactions",
  CARGO_MONEY_TRANSACTIONS_BREADCRUM_TEXT	:	"Cargo Money Transactions",
  CHANGE_PASSWORD_TRANSACTIONS_BREADCRUM_TEXT	:	"Change Password",
  EDIT_ADDRESS_BOOK_BREADCRUM_TEXT		:	"Edit Address Book",
  UPDATE_PROFILE_BREADCRUM_TEXT			:	"Update Profile",
  MY_ACCOUNT_BREADCRUM_TEXT				:	"My Account",
  KYC_BREADCRUM_TEXT					:	"KYC Documents",
  ADD_KYC_BREADCRUM_TEXT				:   "Add KYC Document",
  VALID_IMAGE_TOOLTIP_TEXT				:	'Click here to upload document. Valid extensions are jpeg, jpg and png.',
  CURRENCY_CODE		   					:   'INR',
  PAYMENT_URL							:  'https://api.pcfcourierlogistics.com/paytm/pgRedirect/',
  PAYMENT_SUCCESS_MSG					: "booking payment has been successfully.",
  PAYMEMT_FAILURE_MSG					: "Your payment has failed due to a technical error. Please try again or use a different method to complete the payment.",
  PAYMENT_INVALID_MSG					: "Invalid Access",
  PAYMENT_PENDING_MSG					: "Transaction status not confirmed yet.",
  ROAZERPAY_KEY_ID						: "rzp_test_nWiP8AeddBJxJc",
   
  
   
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
