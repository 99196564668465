import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {HttpParams} from "@angular/common/http";
import { SharedService } from "../../shared/service/shared.service";
import { environment } from '../../../../environments/environment';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoginUserService {
	
	signupUrl			:	any			=	'';
	postCodeUrl			:	any			=	'';
	SITE_API_URL		:	any			=	'';
	data: any = {};

  /**define constructor */
  constructor(private httpClient:HttpClient,public SharedService : SharedService) { 
		this.SITE_API_URL		=	environment.API_URL;
  }
  
	/** Function to submit signup form */
	submitUpdateForm(data:any) {
		
		return this.SharedService.getPost(this.SITE_API_URL,data);
	}
	/** Function to submit signup form */
	getLoginUserData(data:any) {
		return this.SharedService.getPost(this.SITE_API_URL,data);
	}
	
	/** Function to submit address Book */
	submitAddressBookingForm(data:any) {
		data.method_name	=	'saveAddressBook';
		return this.SharedService.getPost(this.SITE_API_URL,data);
	}
	
	
	/**This function are used for get the address book list **/
	getAddressBookListData(data:any) {
        data.append('method_name',	"getAddressbookList");		
		return this.SharedService.getPost(this.SITE_API_URL,data);
	}
	
	/**This functiomn is used for customer quick bookings **/
	getQuickBookingData(data:any) {
        data.append('method_name',"getQuickBookingList");		
		return this.SharedService.getPost(this.SITE_API_URL,data);
	}

	/**This function are used for get the quick booking list for load more**/
	loadMoreQuickBookingResult(data:any,url:string) {
        data.append('method_name',"getQuickBookingList");		
		return this.SharedService.getPost(url,data);
	}								
	
	/**This function are used for get the address book list for load more**/
	loadMoreAddressResult(data:any,url:string) {
        data.append('method_name',"getAddressbookList");		
		return this.SharedService.getPost(url,data);
	}
	
	/**This function are used for edit data**/
	getAddressBookUpdateData(data:any){
		return this.SharedService.getPost(this.SITE_API_URL,data);
	}
	
	/**This function are used Kyc Form List**/
	getKycFormList(){
		this.data.method_name = "kycFormList";
		return this.SharedService.getPost(this.SITE_API_URL, this.data);
	}
	
	/**This function are used for save the kyc documents**/
	submitKycDocumentForm(data:any) {
		this.signupUrl		=	this.SITE_API_URL;
		return this.SharedService.getPost(this.signupUrl,data);
	}
	
	/**This function are used for get the kyc document list**/
	getKycDocumentListData(data:any) {
        data.append('method_name',	"getKycDocumentList");		
		return this.SharedService.getPost(this.SITE_API_URL,data);
	}
	
}
