import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { HttpParams } from "@angular/common/http";
import { SharedService } from "../../shared/service/shared.service";
import { environment } from '../../../../environments/environment';
import { Observable } from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class OrderService {

	signupUrl: any = '';
	postCodeUrl: any = '';
	SITE_API_URL: any = '';
	data: any = {};
	locationListUrl: any = {};
	branchListUrl: any = {};
	bookingUrl: any = {};

	/**define constructor */
	constructor(private httpClient: HttpClient, public SharedService: SharedService) {
		this.SITE_API_URL = environment.API_URL;
	}

	/** Function to submit signup form */
	submitUpdateForm(data: any) {

		return this.SharedService.getPost(this.SITE_API_URL, data);
	}
	/** Function to submit signup form */
	getOrders(data: any) {
		data.append('method_name', "orderList")
		return this.SharedService.getPost(this.SITE_API_URL, data);
	}

	/** Function to submit signup form */
	loadMoreResult(data: any, url: string) {
		data.append('method_name', "orderList")
		return this.SharedService.getPost(url, data);
	}

	/** Function to submit signup form */
	getOrderDetail(consignment_number: any, data: any) {
		data.append('consignment_number', consignment_number)
		return this.SharedService.getPost(this.SITE_API_URL, data);
	}

	/** Function to getBookingFormList */
	getBookingFormList() {
		this.data.method_name = "bookingFormList";
		return this.SharedService.getPost(this.SITE_API_URL, this.data);
	}

	/** Function to getSource location List */
	getSourceLocation(sourcePostCode: any) {

		this.data.method_name = "locationArray";
		this.data.post_code = sourcePostCode;
		return this.SharedService.getPost(this.SITE_API_URL, this.data);
	}


	/**Function for get branches according to source location **/
	getBranches(sourcePostCodeId: any) {

		this.data.method_name = "branchList";
		this.data.location_id = sourcePostCodeId;
		return this.SharedService.getPost(this.SITE_API_URL, this.data);
	}
	
	/**Function is used get brahc detail **/
	getBranchDetail(branchId:any){
		this.data.method_name = "branchDetail";
		this.data.branch_id = branchId;
		return this.SharedService.getPost(this.SITE_API_URL, this.data);
	}


	/**Function for submit the booking form **/
	submitBookingForm(data: any) {

		data.method_name = "getRateCard";
		return this.SharedService.getPost(this.SITE_API_URL, data);
	}

	/**Function for save booking **/
	saveBookingForm(data: any) {

		data.method_name = "saveBooking";
		return this.SharedService.getPost(this.SITE_API_URL, data);
	}
	/**Function for save quick booking **/
	saveQuickBookingForm(data: any) {
		data.method_name = "saveQuickBooking";
		return this.SharedService.getPost(this.SITE_API_URL, data);
	}								   


	/**Function for track parcel data **/
	trackParcel(data: any) {
		data.append('method_name', "trackParcel");
		return this.SharedService.getPost(this.SITE_API_URL, data);
	}

	/**Function for get the group bookings **/
	getGorupBookings(data: any) {
		data.append('method_name', "groupBookings");
		return this.SharedService.getPost(this.SITE_API_URL, data);
	}


	/** Function to get transaction data form loadmore */
	loadMoreGroupResult(data: any, url: string) {
		data.append('method_name', "groupBookings");
		return this.SharedService.getPost(url, data);
	}


	/**Function for add group for booking **/
	submitGroupForm(data: any) {
		data.method_name = "saveGroup";
		return this.SharedService.getPost(this.SITE_API_URL, data);
	}

	/**Function for get group booking detail **/
	groupBooking(group_booking_id: any) {
		this.data.method_name = "groupBookingDetails";
		this.data.group_booking_id = group_booking_id;
		return this.SharedService.getPost(this.SITE_API_URL, this.data);
	}


	/**Function to cancel booking**/

	cancelBooking(data: any) {

		data.method_name = "cancelBooking";
	
		return this.SharedService.getPost(this.SITE_API_URL, data);
	}

	/* Function to get Cancellation Reson*/
	getCancellationReasons() {
		this.data.method_name = "cancellationReasons";
		return this.SharedService.getPost(this.SITE_API_URL, this.data);
	}


	/**  Function to get Less Booking Resons*/
	getLessRatingReasons() {
		this.data.method_name = "reasonsForLessRatings";
		return this.SharedService.getPost(this.SITE_API_URL, this.data);
	}
 
	/**Function to cancel booking**/

	rateBooking(data: any) {
		data.method_name = "saveReviewsRating";
		return this.SharedService.getPost(this.SITE_API_URL, data);
	}
	
	
	/**Function for get the delivery boy information**/
	getDeliveryBoyInformation(otp: any){
		this.data.method_name	 = "getDeliveryBoyInfoByOtp";
		this.data.otp 			= otp;
		return this.SharedService.getPost(this.SITE_API_URL, this.data);
	}
	
	/**Function for submit complaint form **/
	complaintForm(data: any){
		data.method_name = "saveCustomerBookingIssue";
		return this.SharedService.getPost(this.SITE_API_URL, data);
	}	

	
	/**Function for get the customer complaint **/
	getBookingCustomerComplaint(data: any) {
		data.append('method_name', "getCustomerBookingIssue")
		return this.SharedService.getPost(this.SITE_API_URL, data);
	}
	
	
	/** Function to submit signup form */
	loadMoreResultForComplaint(data: any, url: string) {
		data.append('method_name', "getCustomerBookingIssue")
		return this.SharedService.getPost(url, data);
	}
	
	
	/**Function for get the payment detail **/
	getPaymentInformation(data: any) {
		return this.SharedService.getPost(this.SITE_API_URL, data);
	}
	
	/**This function are used for submit the payment form **/
	submitPaymentInformation(data: any) {
		data.method_name		=	'payBookingPayment';
		return this.SharedService.getPost(this.SITE_API_URL, data);
	}
	
	
	
	/**function are used to send detail on stripe payment gateway */
	roazerCharges(data:any) {
		data.method_name		=	'payBookingRozerPayment';
		return this.SharedService.getPost(this.SITE_API_URL,data);
	}
	
	/**This function are used for submit the payment form **/
	submitWalletPaymentInformation(data: any) {
		data.method_name		=	'addMoneyInWallet';
		return this.SharedService.getPost(this.SITE_API_URL, data);
	}
	
	/**function are used to send detail on stripe payment gateway */
	roazerWalletCharges(data:any) {
		data.method_name		=	'payWalletRozerPayment';
		return this.SharedService.getPost(this.SITE_API_URL,data);
	}
	
}
