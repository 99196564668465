import { Component, OnInit, Input } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';

declare var $:any;

@Component({
	selector: 'breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.css'],

})
export class BreadcrumbComponent implements OnInit {
	
	@Input() breadcrumb: any;
	
	constructor(public router: Router) { }

	ngOnInit() {
		
	}
	
	
}
