import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable ,  BehaviorSubject ,  ReplaySubject } from 'rxjs';
import { map ,  distinctUntilChanged } from 'rxjs/operators';
import { User } from '../core/user.model';
import { LoginUserService } from "../modules/user/service/login_user.service";
declare var $:any;

@Injectable()
export class UserService {
  private currentUserSubject = new BehaviorSubject<User>({} as User);
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  constructor (
   
    private http: HttpClient,
    private loginUserService:LoginUserService
  ) {}

  
/*
  

  attemptAuth(type, credentials): Observable<User> {
    const route = (type === 'login') ? '/login' : '';
    return this.apiService.post('/users' + route, {user: credentials})
      .pipe(map(
      data => {
        this.setAuth(data.user);
        return data;
      }
    ));
  }

  getCurrentUser(): User {
    return this.currentUserSubject.value;
  }

  // Update the user on the server (email, pass, etc)
  update(user): Observable<User> {
    return this.apiService
    .put('/user', { user })
    .pipe(map(data => {
      // Update the currentUser observable
      this.currentUserSubject.next(data.user);
      return data.user;
    }));
  }
*/



	/* 
	* Function to get logged in user detail
	*/
  	getCurrentUser() {
    //return this.currentUserSubject.value;
  		let  currentUser = JSON.parse(localStorage.getItem('userData'));
		if (typeof currentUser != 'undefined' && currentUser != null) {		    			
			return currentUser;
	   	}
	   else
	   {
			return currentUser;
	   }	
  	}
  	/* 
	* Function to update loggedin user details
	*/
  	updateCurrentUserData(){
		let currentUser = JSON.parse(localStorage.getItem('userData'));
		if (typeof currentUser != 'undefined' && currentUser != null) {
			let input 			=	new FormData();	
			let loginUserSlug	=	currentUser.slug;
			input.append('slug',loginUserSlug);		
			input.append('method_name',	"editProfile");		
			input.append('api_referrer',"web");			
			this.loginUserService.getLoginUserData(input).subscribe((response: any) => {
				if(response.data.success == true)
				{
					let loginuserData	=	response.data.user_data;
					localStorage.setItem('userData',JSON.stringify(loginuserData));
					setTimeout(function(){
						$('#userDataUpdated').trigger('click');
					},1000);
				}
			});
		}
		
    }
	
	// Verify JWT in localstorage with server & load user's info.
	// This runs once on application startup.
	populate() {
		// If JWT detected, attempt to get & store user's info
		let  currentUser = JSON.parse(localStorage.getItem('userData'));
		if (typeof currentUser != 'undefined' && currentUser != null) {		    			
			this.setAuth(currentUser);
			
	   	} else {
			// Remove any potential remnants of previous auth states
			this.purgeAuth();
		}
	}

	/**function is used to set local storage */
    setAuth(user: User) {
      localStorage.setItem('userData',JSON.stringify(user));
      // Set current user data into observable
      this.currentUserSubject.next(user);
      // Set isAuthenticated to true
      this.isAuthenticatedSubject.next(true);
	}
	/**function is used to remove local storage */
	purgeAuth() {
		// Remove user data from localstorage
		localStorage.removeItem('userData');
		localStorage.removeItem('userDataToken');
		// Set current user to an empty object
		this.currentUserSubject.next({} as User);
		// Set auth status to false
		this.isAuthenticatedSubject.next(false);
	  }
	
	/**function is used to set local storage */
	setToken(token: any){
		localStorage.setItem('token',JSON.stringify(token));	
	}
	  
	  
}
