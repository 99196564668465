import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from "../../shared/service/shared.service";
import { environment } from '../../../../environments/environment';
import 'rxjs/add/operator/map';

@Injectable()
export class ResetPasswordService {
	
  /**define valiables */
	SITE_API_URL			:	any		=	'';
	data					:	any		=	{};
	forgotPasswordOtpUrl    :	any		=	{};
	siteSettingUrl			:	any		=	{};
	submitOtpUrl			:	any		=	{};
	resendOtpUrl			:	any		=	{};
	
  /**Define constructor*/	
  constructor(private httpClient:HttpClient,public SharedService : SharedService) { 
	this.SITE_API_URL		=	environment.API_URL;
  }
  
  /**This function are used for submit the get OTP for reset password **/
  getOtpForResetPassword(data:any){
	  this.forgotPasswordOtpUrl				=	this.SITE_API_URL;
	  data.method_name								=	'forgotPassword';
	  data.api_referrer								=	'web';
	  return this.SharedService.getPost(this.forgotPasswordOtpUrl,data);	
  }
  
  /** This function are used for submit the otp form **/
  submitOTPForm(data:any,method_name:string){
	  this.submitOtpUrl							=	this.SITE_API_URL;
	  data.method_name							=	method_name;
	  data.api_referrer							=	'web';
	  return this.SharedService.getPost(this.submitOtpUrl,data);	
  }
  
   /** This function are used for resend otp **/
  resendOTP(data:any,method_name:string){
	  this.resendOtpUrl							=	this.SITE_API_URL;
		
		data.append('method_name',method_name);
		data.append('api_referrer','web');
	
		
	  return this.SharedService.getPost(this.resendOtpUrl,data);	 
  }
  
  /* 
	* Function to submit resetPassword form on server & get response
	* @params user :- form instance
	*/
	resetPassword(data:any) { 
		 this.resendOtpUrl							=	this.SITE_API_URL;
		data.method_name							=	'resetPasswordSave';
		data.api_referrer							=	'web';
		return this.SharedService.getPost(this.resendOtpUrl,data);	
	}
  
  
	
  //handler function added 
  private handleError (error: Response | any) {
		console.error(error.message || error);
		return Promise.reject(error.message || error);
   }
}


