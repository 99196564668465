import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { RouterModule, Routes, Router } from '@angular/router';
import { UserService } from '../../../../_services/user.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  
  SITE_IMAGE_URL: string = ""; 

  constructor(public router: Router,public userService:UserService) {
    this.SITE_IMAGE_URL = environment.SITE_IMAGE_URL;
  }

  ngOnInit() {

  }
  
  
  /**This function are used for get the cms page **/
  public getCms(slug = ''){ 
	this.router.navigate(['/pages'+'/'+slug]);
	setTimeout(function(){
		$('#cms_description').trigger('click', [slug]);
	},200);
  }

}
