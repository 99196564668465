import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

@Injectable()
export class SharedService {
  /**Define constructor*/	
  constructor(private HttpClient: HttpClient) { }
  
  /**Define get request method*/	
  public getRequest(requestUrl = ''){
		return this.HttpClient.get(requestUrl).toPromise().then(res => {
			return res;
		})
		.catch(this.handleError);
  }
  
   /**Define post request method*/
  public getPost(requestUrl = '', data = ''){
		return this.HttpClient.post(requestUrl,data)
        .map(res => res);	 
  }
  
  /**This function are used for get the token on each request **/
  public getToken(){
		let  currentUser = JSON.parse(localStorage.getItem('token'));
		if (typeof currentUser != 'undefined' && currentUser != null) {		    			
			return currentUser;
		}
	    else
	    {
			return currentUser;
	    }
  }
  
 
	  


  //handler function added 
  private handleError (error: Response | any) {
		console.error(error.message || error);
		return Promise.reject(error.message || error);
   }
}


