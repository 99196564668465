import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { RouterModule, Routes, Router, ActivatedRoute, ParamMap } from '@angular/router';
import { SharedService } from "../../service/shared.service";
import { FooterService } from "../../service/footer.service";
declare var $:any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  providers : [FooterService,SharedService]
})
export class FooterComponent implements OnInit {

  SITE_IMAGE_URL 	:	string	 = "";
  SITE_API_URL		:	string	=	'';
  slug			 	:   any		=	{};	
  blockData			:	any		=	{};
  data				:	any		=	{};
  siteSettingData	:	any		=	{};	
  SITE_ADDRESS		:	any		=	{};
  SITE_NUMBER		:	any		=	{};
  SITE_EMAIL		:	any		=	{};
  FACEBOOK_URL		:	any		=	{};
  TWITTER_URL		:	any		=	{};
  GOOGLE_PLUS_URL	:	any		=	{};
  PINTEREST_URL		:	any		=	{};
  COPY_RIGHT_TEXT	:	any		=	{};
  

  constructor(private FooterService: FooterService,public router: Router,) { 
	this.SITE_IMAGE_URL		=	environment.SITE_IMAGE_URL;
	this.SITE_API_URL		=	environment.API_URL;
	
  }

  ngOnInit() {
	/**This function are used for get the footer block **/
	this.getFooterBlock();	
	
	/**This function are used for get site setting **/
	this.getSiteSetting();
  }
  
  /**This function are used for get the footer block **/
  public getFooterBlock(){
		this.slug	=	'footer-about-us';   
		this.FooterService.getBlockData(this.slug).subscribe((res: any) => {
			this.blockData		=	res.data.data;
		});
  }
  
  /**This function are used for get site setting **/
  public getSiteSetting(){
		this.FooterService.getSiteSettingData().subscribe((res: any) => {
			this.siteSettingData		=	res.data;
			this.SITE_ADDRESS			=	this.siteSettingData.site_settings.site_address;
			this.SITE_NUMBER			=	this.siteSettingData.site_settings.site_contact_phone;
			this.SITE_EMAIL				=	this.siteSettingData.site_settings.site_contact_email;
			this.FACEBOOK_URL			=	this.siteSettingData.social_settings.facebook_url;
			this.TWITTER_URL			=	this.siteSettingData.social_settings.twitter_url;
			this.GOOGLE_PLUS_URL		=	this.siteSettingData.social_settings.gplus_url;
			this.PINTEREST_URL			=	this.siteSettingData.social_settings.pinterest_url;
			this.COPY_RIGHT_TEXT		=	this.siteSettingData.site_settings.site_copyright_text;
		});
  }
  
  
  /**This function are used for get the cms page **/
  public getCms(slug = ''){ 
	this.router.navigate(['/pages'+'/'+slug]);
	setTimeout(function(){
		$('#cms_description').trigger('click', [slug]);
	},200);
  }
  

}
