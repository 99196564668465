import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

declare var unblockUI:any;

@Component({
  templateUrl: './error404.component.html',
})
export class Error404Component implements OnInit {
  
  /*declare contructor */
  constructor() { }
  
  errorTitle          :	  string	=	'';
  errorDescription    :	  string	=	'';
  errorClickHere      :	  string	=	'';

  /*declare initialization */
  ngOnInit() {
	
    this.errorTitle         = environment.ERROR_404;
    this.errorDescription   = environment.ERROR_DESCRIPTION;
    this.errorClickHere     = environment.ERROR_CLICK_HERE;
	unblockUI();
  }
}
