import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from "../../shared/service/shared.service";
import { environment } from '../../../../environments/environment';
import 'rxjs/add/operator/map';

@Injectable()
export class FooterService {
	
  /**define valiables */
	SITE_API_URL		:	any		=	'';
	data				:	any		=	{};
	blockUrl			:	any		=	{};
	siteSettingUrl		:	any		=	{};
	
  /**Define constructor*/	
  constructor(private httpClient:HttpClient,public SharedService : SharedService) { 
	this.SITE_API_URL		=	environment.API_URL;
  }
  
  /**This function are used for get the block data **/
	getBlockData(slug:any){
		this.blockUrl					=	this.SITE_API_URL;
		this.data.method_name			=	'showBlock';
		this.data.api_referrer			=	'web';
		this.data.slug					=	 slug;
		return this.SharedService.getPost(this.blockUrl,this.data);	
	}
	
	/**This function are used for get the site setting data **/
	getSiteSettingData(){
		this.siteSettingUrl				=	this.SITE_API_URL;
		this.data.method_name			=	'getSocicalUrl';
		this.data.api_referrer			=	'web';
		return this.SharedService.getPost(this.siteSettingUrl,this.data);	
	}
	
	
	
  //handler function added 
  private handleError (error: Response | any) {
		console.error(error.message || error);
		return Promise.reject(error.message || error);
   }
}


